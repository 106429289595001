<template>
  <div>
    <v-app-bar color="primary" dark fixed>
      <v-container class="d-flex align-center pa-0">
        <v-btn class="mr-3" small icon dark @click="$emit('closedialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="mt-1">
          <div>
            <h1 class="title font-weight-bold mb-1" style="line-height: 1">
              Dados Empresa
            </h1>
            <h3 class="caption ma-0" style="line-height: 1">
              Informações pessoais e endereço
            </h3>
          </div>
        </v-toolbar-title>
      </v-container>
    </v-app-bar>

    <div class="header-height"></div>

    <v-container class="pa-5 pb-5 pt-0 elevation-0">
      <v-expansion-panels v-model="panel" multiple class="pb-5 elevation-0">
        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dados Responsavel
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.nomeCompleto || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Email</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.email || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CPF</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.cpf | cnpjCpfMask }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Data de Nascimento</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.dataNascimento | convertDateOutput }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Nome da Mãe</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.nomeMae || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Sexo</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.sexo || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Telefone Principal</b> <br>
                {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.telefone || '--' }}
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Telefone Opcional</b> <br>
                  {{ propostaState.empresa.representanteEmpresa && propostaState.empresa.representanteEmpresa.celular || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel class="elevation-0">
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Dados da empresa
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CNPJ</b> <br>
                {{ propostaState.empresa.dadosEmpresa && propostaState.empresa.dadosEmpresa.cnpj | cnpjCpfMask }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>CNAE</b> <br>
                {{ propostaState.empresa.dadosEmpresa && propostaState.empresa.dadosEmpresa.cnae || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Razão Social</b> <br>
                {{ propostaState.empresa.dadosEmpresa && propostaState.empresa.dadosEmpresa.razaoSocial || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Nome Fantasia</b> <br>
                {{ propostaState.empresa.dadosEmpresa && propostaState.empresa.dadosEmpresa.nomeFantasia || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Inscrição Municipal</b> <br>
                {{ propostaState.empresa.dadosEmpresa && propostaState.empresa.dadosEmpresa.inscricaoMunicipal || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>Data Abertura</b> <br>
                {{ propostaState.empresa.dadosEmpresa && propostaState.empresa.dadosEmpresa.dataAbertura | convertDateOutput }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>
            <h1 class="title font-weight-bold mb-1 primary--text" style="line-height: 1">
              Endereço
            </h1>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-divider class="mb-4"></v-divider>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>CEP</b> <br>
                {{ propostaState.empresa.enderecoEmpresa && propostaState.empresa.enderecoEmpresa.cep || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="6" class="mb-2">
                <b>Cidade</b> <br>
                {{ propostaState.empresa.enderecoEmpresa && propostaState.empresa.enderecoEmpresa.cidade || '--' }}
              </v-col>
              <v-col cols="6" class="mb-2">
                <b>UF</b> <br>
                {{ propostaState.empresa.enderecoEmpresa && propostaState.empresa.enderecoEmpresa.estado || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Logradouro</b> <br>
                {{ propostaState.empresa.enderecoEmpresa && propostaState.empresa.enderecoEmpresa.logradouro || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Número</b> <br>
                {{ propostaState.empresa.enderecoEmpresa && propostaState.empresa.enderecoEmpresa.numero || '--' }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="mb-2">
                <b>Bairro</b> <br>
                {{ propostaState.empresa.enderecoEmpresa && propostaState.empresa.enderecoEmpresa.bairro || '--' }}
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
    </v-container>

    <v-footer fixed v-if="showEditButton">
      <v-col class="text-center pa-1" cols="12" >
        <v-btn large color="primary" class="secundaryBtn--text" @click="edit">
          Editar dados
        </v-btn>
      </v-col>
    </v-footer>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { Etapas } from '@/utils/etapas';
import propostaPMEService from '@/services/propostaPMEService';

export default {
  props: {
    showBotao: Boolean,
    etapa: Object,
    validarEdicao: Function,
  },
  data() {
    return {
      panel: [],
      items: 3,
    };
  },
  mounted() {
    this.expandirTodos();
  },
  computed: {
    ...mapState({
      propostaState: (state) => state.PME
    }),
    showEditButton() {
      const statusEdit = ["EM_DIGITACAO", "DEVOLVIDA"];
      if(statusEdit.includes(this.propostaState.empresa.status) && this.showBotao) return true;

      return false;
    },
  },
  methods: {
    ...mapActions({
      setBtnEdit: 'PME/setBtnEdit',
    }),
    expandirTodos() {
      if (this.propostaState.responsavelLegal) this.items = 4;

      const { plano } = this.propostaState;
      if (plano && (plano.nomePai || plano.matricula || plano.patrocinadora || plano.nomePai)) this.items++;

      this.panel = [...Array(this.items).keys()].map((k, i) => i);
    },
    carregarEtapa() {
      const objEtapas = new Etapas();
      objEtapas.loadEtapas();
      this.etapaTitular = objEtapas.getEtapaAtual("dados_titular_contratacao");
    },
    async edit() {
      const permiteEditar = await this.validarEdicao();
      if (!permiteEditar) {
        this.$emit('showmsgeditar');
        this.$emit('closedialog');
        return;
      }

      await propostaPMEService.isEditing({ guid: this.$route.params.guid, isEditing: true, etapaEditing: "dados_empresa" })
      // this.carregarEtapa();
      // const page = 1;
      // this.setBtnEdit({ flag: true, page, isEditing: true });
      this.$router.push({ name: 'areaLogada.PME', params: { guid: this.$route.params.guid }, query: { etapa: "dados_empresa" } });
    },
  },
};
</script>

<style lang="scss" scoped>

  .header-height {
    padding: 20px;
    height: 70px;
  }

</style>
